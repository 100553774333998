import React, { createContext, useContext } from 'react';
import { loginRequest } from '../../authConfig';
import { useMsal } from '@azure/msal-react';

const UserContext = createContext();

export const useAuth = () => useContext(UserContext);


export const UserProvider = ({children}) => {
    const { instance } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();   
    }

    const login = () => {
        instance
        .loginPopup({
            ...loginRequest,
            redirectUri: '/redirect',
        })
        .catch((error) => console.log(error));
    }

    const loginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }

    const logout = () => {
        instance
        .logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
            account: instance.getActiveAccount(),
        })
        .catch((error) => console.log(error));
    }

    const logoutRedirect = () => {
        instance.logoutRedirect().catch((error) => console.log(error));
    }

	return (
		<UserContext.Provider value={{activeAccount, login, loginRedirect, logout, logoutRedirect}}>
            {children}
		</UserContext.Provider>
	);
};


export default UserContext;