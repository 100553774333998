import { React, useState } from 'react';
import '../../styles/config.css';
import { NavLink } from 'react-router-dom';
import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { ADMINISTRATION_TEXT, HISTORY } from '../../constants/constants';


const Config = ({botName, menuItem, path, state}) => {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar)
    }

  return (
    <nav className="config-items">
        <h5>{botName}</h5>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img className="hamburger-menu" src={HamburgerIcon} alt="hamburger-menu"></img>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
            <ul className='config-list'>
                <li className='config-list-item'>
                    <NavLink to="/default" className='config-list-item-link'>{ADMINISTRATION_TEXT}</NavLink>
                </li>
                <li className='config-list-item'>
                  <NavLink to={{ pathname: path }} state={state} className='config-list-item-link'>{menuItem}</NavLink>
                </li>
                <li className='config-list-item'>
                    <NavLink to="/default" className='config-list-item-link'>{HISTORY}</NavLink>
                </li>
            </ul>
        </div>
    </nav>
  )
}

export default Config;