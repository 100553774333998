import React from 'react';
import './App.css';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import { UserProvider } from './services/Config/userContext';
import DoorConfigComponent from './components/Common/doorConfig';
import Home from './components/Common/home';
import NavbarLayout from './components/Common/navbar';
import SidebarLayout from './components/Common/sidebarLayout';
import SelectCorpus from './components/SmartDocuChatBot/selectCorpus';
import PageNotFound from './components/Common/default';
import UploadDocs from './components/Admin/uploadFile';
import PromptCraftComponent from './components/PromptCraft/promptCraft';
import ChatBotComponent from './components/OtherBots/otherChatBots';
import PMOComponent from './components/OtherBots/pmoAssistant';
import TestCaseGenerator from './components/TestGenerator/testCaseGenerator';
import Footer from './components/Common/footer';


const App = ({instance}) => {
  return (
      <MsalProvider instance={instance}>
        <UserProvider>
            <BrowserRouter>
                    <Routes>
                        <Route element={<NavbarLayout/>}>
                          <Route path="/" element={<Home />}></Route>
                          <Route element={<SidebarLayout/>}>
                            <Route path="/ed.ai" element={<SelectCorpus />}></Route>
                            <Route path="/ed.ai/doorconfig" element={<DoorConfigComponent />}></Route>
                            <Route path="default" element={<PageNotFound />}></Route>
                            <Route path="/ed.ai/admin" element={<UploadDocs />} ></Route>
                            <Route path="/ed.ai/prompt" element={<PromptCraftComponent />} ></Route>
                            <Route path="/ed.ai/bots" element={<ChatBotComponent />} ></Route>
                            <Route path="/ed.ai/pmo" element={<PMOComponent />} ></Route>
                            <Route path="/ed.ai/test" element={<TestCaseGenerator />}></Route>
                          </Route>
                        </Route>
                    </Routes>
            </BrowserRouter> 
        </UserProvider>
            <Footer />
      </MsalProvider>
  );
}


App.propTypes = {
    instance: PropTypes.object
};

export default App;
