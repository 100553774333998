import { Outlet } from 'react-router-dom';
import SideMenu from './sideMenu';

const SidebarLayout = () => (
  <>
    <SideMenu />
    <Outlet />
  </>
);

export default SidebarLayout;