import React from "react";
import PropTypes from 'prop-types';
import '../../styles/sidebar.css';
import { Link } from "react-router-dom";

const SidebarLink = ({onLinkClick, activeItem, url, pathname, secondaryPath, botName, botInitials, defaultIcon, activeIcon}) => {

    return(
    <>
       {(url) ? (<Link to={{ pathname: pathname }} state={{url: secondaryPath ? `${url}/${secondaryPath}/` : `${url}`, botName: botName, bot: botInitials}}  className='sideMenu-link' onClick={onLinkClick}>
            <li className='item'>
                <div className={activeItem === botInitials ? "bot-icon selected" : "bot-icon"}>
                    <img src={activeItem === botInitials ? activeIcon : defaultIcon} alt={botName}/>
                    <span>{botName}</span>
                </div>
            </li>
        </Link>) : (
        <Link to={{ pathname: pathname }} className='sideMenu-link' onClick={onLinkClick}>
            <li className='item'>
                <div className={activeItem === botInitials ? "bot-icon selected" : "bot-icon"}>
                    <img src={activeItem === botInitials ? activeIcon : defaultIcon} alt={botName}/>
                    <span>{botName}</span>
                </div>
            </li>
        </Link>
        )}
    </>
    )
}

SidebarLink.propTypes = {
    onLinkClick: PropTypes.func,
    activeItem: PropTypes.string,
    url: PropTypes.string,
    botInitials: PropTypes.string,
    botName: PropTypes.string,
    pathname: PropTypes.string,
    secondaryPath: PropTypes.string,
    defaultIcon: PropTypes.string,
    activeIcon: PropTypes.string
  };
  

export default SidebarLink;