import React from "react";
import PropTypes from 'prop-types';
import '../../styles/home.css';
import { Link } from "react-router-dom";

const DashboardLink = ({searchVal, url, pathname, secondaryPath, botName, defaultIcon, botInitials }) => {

    return(
    <>
       {(url && secondaryPath) ? (<>
       {(botName.toLowerCase().includes(searchVal?.toLowerCase()) || searchVal === '') && (<Link to={pathname} 
       state={{url: `${url}/${secondaryPath}/`, botName: botName, bot: botInitials}} className="dashboard-link">
            <li className="tool-item">
                <div className="tool-icon">
                    <img src={defaultIcon} alt={botName}/>
                    <span className="tool-text">{botName}</span>
                </div>
            </li>
        </Link>)}
        </>) : (<> 
        {(botName.toLowerCase().includes(searchVal?.toLowerCase()) || searchVal === '') && (<Link to={pathname} state={{botName: botName, bot: botInitials}} className="dashboard-link">
            <li className="tool-item">
                <div className="tool-icon">
                    <img src={defaultIcon} alt={botName}/>
                    <span className="tool-text">{botName}</span>
                </div>
            </li>
        </Link>)}
        </>
        )}
    </>
    )
}

DashboardLink.propTypes = {
    searchVal: PropTypes.string,
    url: PropTypes.string,
    botInitials: PropTypes.string,
    botName: PropTypes.string,
    pathname: PropTypes.string,
    secondaryPath: PropTypes.string,
    defaultIcon: PropTypes.string,
  };
  

export default DashboardLink;