import React from 'react';
import DocChatLoader from '../SmartDocuChatBot/docChatLoader';
import { PMO, PMO_ASSISTANT } from '../../constants/constants';

const PMOComponent = () => {

  return (
      <DocChatLoader wrapperClass={'chat-prompt'} chatHeader={PMO_ASSISTANT} corpus={PMO} chatWrapperClass={'web-chat-prompt-window'}/>
  )
};

export default PMOComponent;