import React, { useCallback, useEffect, useState } from 'react';
import { CODE_SNIPPET_AS_SAMPLE, DEVELOPMENT, ERROR_OCCURED, GENERATE_SELENIUM_SCRIPT, GENRATE_MANUAL_TEST, PLACEHOLDER_TEXT, REQUIREMENTS_AS_INPUT, SELECT_INPUT_TYPE, TEST_GENERATOR, TEST_GROUP, TEXT_IS_LOADING } from '../../constants/constants';
import { Button, Form } from 'react-bootstrap';
import "../../styles/testGenerator.css";
import axios from 'axios';


const TestCaseGenerator = () => {
    const [radioLabel, setRadioLabel] = useState([{
        label: REQUIREMENTS_AS_INPUT,
        value: REQUIREMENTS_AS_INPUT,
        group: TEST_GROUP
    }, {
        label: CODE_SNIPPET_AS_SAMPLE,
        value: CODE_SNIPPET_AS_SAMPLE,
        group: TEST_GROUP
    }]);
    const [radioInputValue, setRadioInputValue] = useState('');
    const [textAreaVal, setTextAreaVal] = useState('');
    const [testOutput, setTestOutput] = useState('');

    useEffect(() => {
        setRadioLabel([{
            label: REQUIREMENTS_AS_INPUT,
            value: REQUIREMENTS_AS_INPUT,
            group: TEST_GROUP
        }, {
            label: CODE_SNIPPET_AS_SAMPLE,
            value: CODE_SNIPPET_AS_SAMPLE,
            group: TEST_GROUP
        }])
    }, []);

    useEffect(() => {
        console.log(radioInputValue);
    }, [radioInputValue]);

    const generateManualTest = useCallback(async () => {
        setTestOutput(TEXT_IS_LOADING);
        const baseURI = process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_TEST_BOT_BASE_URL : process.env.REACT_APP_BOT_BASE_URL;
        try {
            const response = await axios({
                method: 'post', 
                data: radioInputValue === REQUIREMENTS_AS_INPUT ? {requirements: textAreaVal} : {code: textAreaVal},
                headers: {
                    'Content-Type': 'application/json'
                },
                url: radioInputValue === REQUIREMENTS_AS_INPUT ? `${baseURI}/testagent/generate/manual_test_cases/requirements` :
                `${baseURI}/testagent/generate/manual_test_cases/code`,
            });
            if (response && response?.data && response?.status === 200) {
                const data = response.data;
                setTestOutput(data?.manual_test_cases);
            } 
        } catch(error) {
            setTestOutput(ERROR_OCCURED);
            console.log(error.response?.data?.error);
        }
    },[radioInputValue, textAreaVal])

    const generateSeleniumScript = useCallback(async () => {
        setTestOutput(TEXT_IS_LOADING);
        const baseURI = process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_TEST_BOT_BASE_URL : process.env.REACT_APP_BOT_BASE_URL;
        try {
            const response = await axios({
                method: 'post', 
                data: radioInputValue === REQUIREMENTS_AS_INPUT ? {requirements: textAreaVal} : {code: textAreaVal},
                headers: {
                   'Content-Type': 'application/json'
                },
                url:radioInputValue === REQUIREMENTS_AS_INPUT ?  `${baseURI}/testagent/generate/selenium_script/requirements` : `${baseURI}/testagent/generate/selenium_script/code`,
            });
            if (response && response?.data && response?.status === 200) {
                  const data = response.data;
                  setTestOutput(data?.selenium_script);
            } 
        } catch(error) {
            setTestOutput(ERROR_OCCURED);
            console.log(error.response?.data?.error);
        }
    },[radioInputValue, textAreaVal])


    return (
        <div className='test-case-wrapper'>
            <h5>{TEST_GENERATOR}</h5>
            <div className='select-input'>{SELECT_INPUT_TYPE}</div>
            <div className="radio-group-inputs my-3">
            <Form>
                {radioLabel?.map((val, index) => (
                    
                    <Form.Check
                    inline
                    key={index}
                    label={val.label}
                    name={val.group}
                    value={val.value}
                    onChange={() => setRadioInputValue(val.value)}
                    type='radio'
                    className='radio-label'
                    id={`inline-radio-${index}`}
                />
                ))}
                </Form>
            </div>
            <div className='prompt-text-field'>
                <Form.Control placeholder={PLACEHOLDER_TEXT} disabled={radioInputValue === ''} aria-disabled={radioInputValue === ''} className="mb-3 prompt-textarea" as="textarea" size="sm" rows={8} onChange={(e) => setTextAreaVal(e.target.value)} defaultValue={textAreaVal}/>
            </div>
            <div className='test-case-buttons'>
                <Button type="button" variant="secondary" className='generate-test-btn' onClick={generateManualTest} disabled={radioInputValue === '' || textAreaVal === '' || textAreaVal === ERROR_OCCURED} aria-disabled={radioInputValue === '' || textAreaVal === '' || textAreaVal === ERROR_OCCURED}>{GENRATE_MANUAL_TEST}</Button>
                <Button type="button" variant="secondary"  className='generate-test-btn' onClick={generateSeleniumScript} disabled={radioInputValue === '' || textAreaVal === ''  || textAreaVal === ERROR_OCCURED} aria-disabled={radioInputValue === '' || textAreaVal === '' || textAreaVal === ERROR_OCCURED}>{GENERATE_SELENIUM_SCRIPT}</Button>
            </div>
            <div className='test-output-section'>
                <pre><code>{testOutput}</code></pre>
            </div>
        </div>
    )
}

export default TestCaseGenerator;