import React, { useEffect, useState } from 'react';

import DocSearchChatBot from './docSearchChatBot';
import '../../styles/chatWindow.css';
import PropTypes from 'prop-types';
import { PLEASE_LOGIN_TO_CONTINUE } from '../../constants/constants';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAuth } from '../../services/Config/userContext';

const DocChatLoader = ({corpus, chatHeader, close, wrapperClass, chatWrapperClass}) => {
  const [headerName, setHeaderName] = useState();
  const [corpusName, setCorpusName] = useState();
  const [userName, setUserName] = useState();
  const { activeAccount } = useAuth();

  useEffect(() => {
    setCorpusName(corpus);
    setHeaderName(chatHeader);
  }, [corpus, chatHeader]);

  useEffect(() => {
    if (activeAccount) {
      setUserName(activeAccount.name);
    }
  }, [userName, activeAccount]);


  return (
  <div className={wrapperClass} key={corpusName}>
    <AuthenticatedTemplate>
      <div className="chat-wrapper">
        <DocSearchChatBot filename='' username={userName} corpus={corpusName} headerName={headerName} close={close} chatWrapperClass={chatWrapperClass}/>
      </div>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <p className={`${wrapperClass}-unauthorized`}>{PLEASE_LOGIN_TO_CONTINUE}</p>
    </UnauthenticatedTemplate>
 
  </div>
)
};

DocChatLoader.propTypes = {
  corpus: PropTypes.string,
  chatHeader: PropTypes.string,
  close: PropTypes.func,
  wrapperClass: PropTypes.string,
  chatWrapperClass: PropTypes.string,
};

export default DocChatLoader;