import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import "../../styles/promptCraft.css";
import "../../styles/dataTable.css";
import { Button, Form } from 'react-bootstrap';
import { ColorRing } from 'react-loader-spinner';
import axiosConfig from '../../services/Config/httpCommon';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import useWindowSize from '../Common/useWindowSize';
import SaveIcon from "../../assets/icons/save.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import EnableSwitch from "../../assets/icons/Switch-e.svg";
import DisableSwitch from "../../assets/icons/Switch-d.svg";
import moment from 'moment';
import { CHAT, CHAT_TEXT, DELETE, DEVELOPMENT, ENABLE_DISABLE, ERROR_TEXT, MY_INSTRUCTION, PLEASE_LOGIN_TO_CONTINUE, PROMPT_ID, SAVE, SEARCH_PROMPT_CRAFT } from '../../constants/constants';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAuth } from '../../services/Config/userContext';
import Config from '../Common/config';

const PromptCraftComponent = () => {
  const [userName, setUserName] = useState();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [botName, setBotName] = useState();
  const [filtered, setFiltered] = useState([]);
  const [path, setPath] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [botHeader, setBotHeader] = useState();
  const [tableData, setTableData] = useState([{
    promptId: null,
    BotName: '',
    Instruction: '',
    Username: '',
    IsActive: 1,
    RKey: '',
    PKey: ''
  }]);
  const [url, setUrl] = useState();
  const location = useLocation();
  const size = useWindowSize();
  const navigate = useNavigate();
  const { activeAccount } = useAuth();

  const customStyles = {
    cells: {
      style: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  };

  useEffect(() => {
    if (activeAccount) {
      setUserName(activeAccount.name);
    }
  }, [userName, activeAccount]);

  const columns = [
    {
        name: PROMPT_ID,
        selector: (row, index) => index + 1,
        wrap: true,
        width: '120px',
        center: "true",
        sortable: true,
    },
    {
        name: MY_INSTRUCTION,
        selector: (row, index) => setEditableField(row, index),
        wrap: true,
        width: '620px',
        sortable: true,
    },
    {
      name: 'Status',
      selector: (row, index) => showStatus(row, index),
      wrap: true,
      width: '100px',
      center: "true",
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, index) => showAllBtn(row, index),
      wrap: true,
      width: '120px',
      sortable: true,
    },
  ];

  const updateInputValue = (e, row) => {
    row.Instruction = e.target.value ;
    setTableData([...tableData]);
  }


  const setEditableField = (row, index) => {
    return (
      <Form.Control disabled={row.IsActive === 0} aria-disabled={row.IsActive === 0} className="textarea-section" as="textarea" size="sm" rows={2} onChange={(e) => updateInputValue(e, row)} defaultValue={row.Instruction} />
    )
  }

  const addRow = (index) => {
    const newData = {
    promptId: index, 
    IsActive: 1,
    BotName: '',
    Instruction: '', 
    Username: '',
    RKey: '',
    PKey: ''
   };
    setTableData([...tableData, newData ])
  }


  const showStatus = (row, index) => {
    return (<div className='all-icons'>
       <img className='prompt-btn' src={row.IsActive === 1 ? EnableSwitch : DisableSwitch} alt={ENABLE_DISABLE} onClick={() => modifyInstruction(row, index)} />
    </div>)
  }

  const showAllBtn = (row, index) => {
    return (
    <div className='all-icons'>
      <img className='prompt-btn' src={SaveIcon} alt={SAVE} onClick={() => saveRow(row, index)} />
      <img className='prompt-btn' src={DeleteIcon} alt={DELETE} onClick={() => deleteRow(row, index)}/>
    </div>)
  }

  const deleteRow = (row, index) => {
    const remainingItems = tableData.filter((val, i) => index !== i);
    setTableData(remainingItems);
    if (index === 0 && tableData.length === 1) {
      resetData();
    }
    if (row.RKey !==  '' && row.Instruction !== '') {
      deleteData(row);   
    }
  }

  const saveRow = (row, index) => {
      const payload = row.RKey === '' ? [{
          BotName: botName,
          Instruction: row.Instruction,
          SeqNo: index + 1,
          IsActive: row.IsActive,
          Username: userName
      }] :  {
         PartitionKey: row.PKey,
         RowKey: row.RKey,
         Timestamp: moment(new Date().toISOString()).format(),
         BotName: botName,
         Instruction: row.Instruction,
         SeqNo: index + 1,
         IsActive: row.IsActive,
         Username: userName
    };
    if (row.Instruction !== '') {
      row.RKey === '' ? createAllPrompts(payload) :  updatePromptCrafts(payload);
    }
  }

  const modifyInstruction = (row, index) => {
    row.IsActive = row.IsActive === 0 ? 1 : 0;
    setTableData([...tableData]);
    saveRow(row, index)
  }

  const resetData = () => {
    setTableData([{
      promptId: null,
      BotName: '',
      Instruction: '',
      IsActive: 1,
      Username: '',
      RKey: '',
      PKey: ''
    }])
  }

  useEffect(() => {
    const bot = location?.state?.bot;
    setBotHeader(location?.state?.botName);
    const baseURL = process.env.REACT_APP_BOT_BASE_URL;
    setBotName(bot);
    getAllPrompts(bot);
    if (bot) {
      switch(bot) {
        case 'HRA': {
          setPath('/ed.ai/bots');
          setUrl(`${baseURL}/hrbot/`);
          break;
        }
        case 'HRA +': {
          setPath('/ed.ai/bots');
          setUrl(`${baseURL}/hrplus/`);
          break;
        }
        case 'NRL': {
          setPath('/ed.ai/bots');
          setUrl(`${baseURL}/nrlbot/`);
          break;
        }
        case 'SQLA': {
          setPath('/ed.ai/bots');
          setUrl(`${baseURL}/agent/`);
          break;
        }
        case 'PMO': {
          location?.state?.prevPath === CHAT ? setPath('/ed.ai/pmo'): setPath('/ed.ai');
          setUrl();
          break;
        }
        case 'Test': {
          setPath('/ed.ai/test');
          setUrl();
          break;
        }
        default: {
          setPath('/ed.ai')
          setUrl();
          break;
        }
      }
    } else {
      navigate('/');
    }
  }, [location, setBotName, navigate]);


  const getAllPrompts = async (bot) => {
    setLoader(true);
    try {
        const response = await axiosConfig({
            method: 'post', 
            data: {
              BotName: bot
            },
            headers: {
                'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_GET_PROMPT_LOCAL_API_KEY :
                 process.env.REACT_APP_GET_PROMPT_API_KEY,
                'Content-Type': 'multipart/form-data'
            },
            url: `GetAllPromptCrafts`
        });
        if (response && response?.data && response?.status === 200) {
                const data = response.data;
                if (data && data.length) {
                  const displayData = data.map((val, index) => {
                    return {
                      promptId: index + 1,
                      BotName: val.BotName,
                      IsActive: val.IsActive,
                      Instruction: val.Instruction,
                      Username: val.Username,
                      RKey: val.RowKey,
                      PKey: val.PartitionKey,
                    }
                  });
                setTableData(displayData);
              }
                setLoader(false);
                setError(false);
        } 
    } catch(error) {
        setLoader(false);
        console.log(error.response?.data?.error);
        setError(true);
    }
  }

  const createAllPrompts = async (payload) => {
    setLoader(true);
    try {
        const response = await axiosConfig({
            method: 'post', 
            data: payload,
            headers: {
                'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_CREATE_PROMPT_LOCAL_API_KEY :
                 process.env.REACT_APP_CREATE_PROMPT_API_KEY,
                'Content-Type': 'application/json'
            },
            url: `CreatePromptCrafts`
        });
        if (response && response?.data && response?.status === 200) {
               getAllPrompts(botName);
        } 
    } catch(error) {
        setLoader(false);
        console.log(error.response?.data?.error);
        setError(true);
    }
  }

  const deleteData = async (row) => {
    setLoader(true);
    try {
        const response = await axiosConfig({
            method: 'post', 
            data: {
              PKey: row.PKey,
              RKey: row.RKey
            },
            headers: {
                'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_DELETE_PROMPT_LOCAL_API_KEY 
                : process.env.REACT_APP_DELETE_PROMPT_API_KEY,
                'Content-Type': 'multipart/form-data'
            },
            url: `DeletePromptCraft`
        });
        if (response && response?.data && response?.status === 200) {
               getAllPrompts(botName);
        } 
    } catch(error) {
        setLoader(false);
        console.log(error.response?.data?.error);
        setError(true);
    }
  }

  const updatePromptCrafts = async (payload) => {
    setLoader(true);
    try {
        const response = await axiosConfig({
            method: 'post', 
            data: payload,
            headers: {
                'x-functions-key': process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_UPDATE_PROMPT_LOCAL_API_KEY :
                 process.env.REACT_APP_UPDATE_PROMPT_API_KEY,
                'Content-Type': 'appliction/json'
            },
            url: `UpdatePromptCraft`
        });
        if (response && response?.data && response?.status === 200) {
               getAllPrompts(botName);
        } 
    } catch(error) {
        setLoader(false);
        console.log(error.response?.data?.error);
        setError(true);
    }
  }

  useEffect(() => {
    if (searchVal !== '') {
      const filteredData = tableData.filter((val) => val.Instruction?.toLowerCase()?.includes(searchVal?.toLowerCase()))
      setFiltered(filteredData);
    } else {
      setFiltered(tableData);
    }
  }, [tableData, searchVal]);

  return (<div className="prompt-container-wrapper">
            <Config botName={`${botName} Prompt Studio`} menuItem={CHAT_TEXT} path={path} state={{url, botName: botHeader, bot: botName}} />
          <hr/>
          <AuthenticatedTemplate>
          {loader && <div className="loading-icon"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>}
          {!loader && !error && (
            <>
              <div className='search-section'>
                <Form.Control type="text" className='search-field' placeholder={SEARCH_PROMPT_CRAFT} value={searchVal} onChange={(e) => setSearchVal(e.target.value)}/>
                <Button className='submit-prompt' type="button" variant='secondary' onClick={() => addRow(tableData.length)}>
                  <FaPlus className='plus-icon' size="14px"/> Add New
                </Button>
              </div>
              <DataTable
                className='dataTables_wrapper'
                columns={columns}
                data={filtered}
                pagination
                responsive
                customStyles={customStyles}
                fixedHeader={true}
                fixedHeaderScrollHeight={size.height > 1000 ? size.width <= 850 ? "40vh" : "63vh" : "48vh"}
              />
          </>)}
          {!loader && error && (<p className="error-msg">{ERROR_TEXT}</p>)}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>{PLEASE_LOGIN_TO_CONTINUE}</p>
          </UnauthenticatedTemplate>
      </div>)
};

export default PromptCraftComponent;