import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import "../../styles/modal.css";
import { PLEASE_LOGIN_TO_CONTINUE } from "../../constants/constants";

const UnauthorizedComponent = ({show, close, title }) => {

    return(
        <Modal show={show} backdrop="false" centered backdropClassName="unauth-modal">
        <Modal.Dialog>
          <Modal.Header closeButton onClick={close}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <p>{PLEASE_LOGIN_TO_CONTINUE}</p>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    )
}

UnauthorizedComponent.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
};

export default UnauthorizedComponent;