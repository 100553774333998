import { useCallback, useEffect, useState } from "react";
import "../../styles/corpus.css";
import "../../styles/dataTable.css";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import DocChatLoader from "./docChatLoader";
import axiosConfig from "../../services/Config/httpCommon";
import useWindowSize from "../Common/useWindowSize";
import ChatIcon from "../../assets/icons/chatbot.svg";
import PromptIcon from "../../assets/icons/prompt-craft.svg";
import AdminIcon from "../../assets/icons/admin.svg";
import { ADMINISTER, ADMINISTRATION, ALL, CHAT, CHAT_TEXT, CORPUS_NAME, DESCRIPTION, DEVELOPMENT, ERROR_TEXT, PROMPT, PROMPT_CRAFT, SEARCH_CORPUS, SMART_DOC_SEARCH_BOT } from "../../constants/constants";
import { Form } from "react-bootstrap";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import UnauthorizedComponent from "../Common/unauthenticated";


const SelectCorpus = () => {
    const { instance } = useMsal();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [filtered, setFiltered] = useState([]);
    const navigate = useNavigate();
    const [checkedItems, setCheckedItems] = useState([]);
    const [corpuses, setCorpuses] = useState([]);
    const [show, setShow] = useState(true);
    const size = useWindowSize();
  

    let activeAccount;
  
    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const getChatIcons = (cname, item) => {
        return (
            <>
            {item === CHAT && (<img src={ChatIcon} alt="Chat with Bot" className="chat-icon" onClick={() => navigateToChatWindow(cname)}/>)}
            {item === ADMINISTRATION && (cname === ALL ? null : (<img src={AdminIcon} alt={ADMINISTER} className="chat-icon" onClick={() => navigateToAdmin(cname)}/>))}
            {item === PROMPT && (<img src={PromptIcon} alt="Prompt Engineering" className="chat-icon" onClick={() => navigateToPromptCraft(cname)}/>)}
            </>
        )
    }

    const columns = [
        {
            name: CORPUS_NAME,
            selector: row => row.CName,
            width: '200px',
            sortable: true,
        },
        {
            name: DESCRIPTION,
            selector: row => row.Description,
            wrap: true,
            sortable: true,
        },
        {
            name: CHAT_TEXT,
            width: '100px',
            selector: row => getChatIcons(row.CName, CHAT),
        },
        {
            name: ADMINISTER,
            width: '100px',
            selector: row => getChatIcons(row.CName, ADMINISTRATION),
        },
        {
            name: PROMPT_CRAFT,
            width: '150px',
            selector: row => getChatIcons(row.CName, PROMPT),
        }
    ];

    useEffect(() => {
        if (activeAccount && checkedItems) {
            setShow(false);
        } else {
            setShow(true);
        }
      }, [activeAccount, checkedItems]);

    const getAllCorpuses = async () => {
        setLoader(true);
        try {
            const response = await axiosConfig({
                method: 'post', 
                headers: {
                    'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_GET_CORPUS_LOCAL_API_KEY :
                     process.env.REACT_APP_GET_CORPUS_API_KEY,
                    'Content-Type': 'multipart/form-data'
                },
                url: `GetAllCorpus`,
            });
            if (response && response?.data && response?.status === 200) {
                    const data = response.data;
                    setCorpuses(data);
                    setTableData(data);
                    setFiltered(data);
                    setLoader(false);
                    setError(false);
            } 
        } catch(error) {
            setLoader(false);
            console.log(error.response?.data?.error);
            setError(true);
        }
    }

    const navigateToAdmin = useCallback((cname) => {
        navigate('/ed.ai/admin', {
            state: {
                header: SMART_DOC_SEARCH_BOT,
                filename: '',
                corpus: cname,
                allCorpuses: corpuses.map((val) => {return val.CName}),
            }
        });
    },[navigate, corpuses]);


    useEffect(() => {
        getAllCorpuses ();
    }, []);

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            if (searchVal !== '') {
                const filteredData =  tableData.filter((val) => val.CName?.toLowerCase()?.includes(searchVal?.toLowerCase()));
                setFiltered(filteredData);
            } else {
                setFiltered(tableData);
            }
        }
    }, [searchVal, tableData])

    const navigateToChatWindow = (cname) => {
        setCheckedItems([...checkedItems, cname]);
    };

   const generateChatPopUp = () => {
        return (
        <>
            {checkedItems && checkedItems.length !== 0 && (<div>
            {checkedItems?.map((corpusName, index) => ( 
                <DocChatLoader key={corpusName} corpus={corpusName} chatHeader={`${SMART_DOC_SEARCH_BOT}-${corpusName}`} close={() => removeItem(index)} 
                wrapperClass={'chat'} chatWrapperClass={'web-chat-window'}/>
            ))}
            </div>)}
        </>
        )
    };

    const removeItem = (index) => {
        setCheckedItems(
            checkedItems.filter((val, i) =>
              i !== index
            )
          )
    };

    const navigateToPromptCraft = (cname) => {
        navigate("/ed.ai/prompt", {
            state: {
                bot: `${cname}`
            }
        });
    };


    return(
        <div className="corpus-wrapper">
        {loader && (<div className="loading-icon"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>)}
            {!loader && !error && (<div className="corpus-container-wrapper">
            <h5>{SMART_DOC_SEARCH_BOT}</h5>
            <Form.Control type="text" className='search-corpus-field' placeholder={SEARCH_CORPUS} value={searchVal} onChange={(e) => setSearchVal(e.target.value)}/>
            {corpuses && corpuses.length !== 0 && ( 
               <div className="table-container-wrapper">
                    <DataTable
                        className='dataTables_wrapper'
                        columns={columns}
                        data={filtered}                    
                        pagination
                        responsive
                        fixedHeader={true}
                        fixedHeaderScrollHeight={size.height >= 650 ? "80vh" : "55vh"}
                    />
                </div>)}
                <AuthenticatedTemplate>
                    {generateChatPopUp()}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {checkedItems && checkedItems.length !== 0 && (<div>
                        {checkedItems?.map((corpusName) => ( 
                        <UnauthorizedComponent key={corpusName} show={show} close={() => setShow(false)} title={`${corpusName} Corpus`} />
                        ))}
                    </div>)}
                </UnauthenticatedTemplate>
            </div>)}
            {!loader && error && (<p className="error-msg">{ERROR_TEXT}</p>)}
        </div>
    )
}

export default SelectCorpus;