import React from 'react';
import PropTypes from 'prop-types';
import "../../styles/results.css";
import { ColorRing } from 'react-loader-spinner';
import { DOOR_DESIGN, ERROR_TEXT } from '../../constants/constants';


const DesignComponent = ({designs, loading, error}) => {

    return (
        <div className='results-wrapper'>
            <div className='results-header'>
                <h6>{DOOR_DESIGN}</h6>
            </div>
            {loading && <div className="loading-result-icon"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>}
            {!loading && !error && ( <div className='results-container'>
                <ul className='design-lists'>
                    {designs.map((val, index) => (
                        <li key={val.DESIGN_ID+'_'+index} className='design-item'>
                            <div className="design-image">
                                <img src={val.IMAGE_URL} alt={'image'+index} className='design-image-item'/>
                                <div className='design-name'>
                                    {val.DESIGN_NAME}
                                </div>
                            </div>
                        </li> 
                    ))}
                </ul>
            </div>)}
            {!loading && error && (<p className="error-msg">{ERROR_TEXT}</p>)}
        </div>
    )
}


DesignComponent.propTypes = {
    designs: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.bool
  };

export default DesignComponent;