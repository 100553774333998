import { useEffect, useState } from "react";
import "../../styles/upload.css";
import { ColorRing } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import ModalComponent from "../Common/modal";
import { Button, Dropdown } from "react-bootstrap";
import axiosConfig from "../../services/Config/httpCommon";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../Common/useWindowSize";
import { BROWSE, CREATE_CORPUS, DEVELOPMENT, ERROR_TEXT, PLEASE_LOGIN_TO_CONTINUE, UPLOAD_SUBTEXT, UPLOAD_TEXT } from "../../constants/constants";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useAuth } from "../../services/Config/userContext";

const UploadDocs = () => {
    const [loader, setLoader] = useState(false);
    const [corpusItem, setCorpusItem] = useState();
    const [fileObj, setFileObj] = useState();
    const [error, setError] = useState(false);
    const [userFiles, setUserFiles] = useState([]);
    const [corpuses, setCorpuses] = useState([]);
    const [show, setShow] = useState(false);
    const [filename, setFilename] = useState();
    const [userName, setUserName] = useState();
    const location = useLocation();
    const navigate =  useNavigate();
    const size = useWindowSize();
    const { activeAccount } = useAuth();

    const columns = [
        {
            name: 'Name',
            selector: row => row.Name,
            wrap: true,
            sortable: true,
        },
        {
            name: 'URL',
            selector: row => row.URL,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Size',
            selector: row => row.Size,
            sortable: true,
        }
    ];

    useEffect(() => {
        const cname = location?.state?.corpus;
        setCorpusItem(cname);
        if (cname && cname !== "All") {
            getCorpusDocuments(cname);
        } else {
            navigate('/');
        }
    }, [location, navigate]);

    useEffect(() => {
        if (activeAccount) {
          setUserName(activeAccount.name);
        }
      }, [userName, activeAccount]);

    const addFile = async () => {
        try {
            const response = await axiosConfig({
                method: 'post',
                headers: {
                    'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_UPLOAD_LOCAL_API_KEY :
                     process.env.REACT_APP_UPLOAD_API_KEY,
                    'Content-Type': 'multipart/form-data'
                }, 
                url: `SAFileUpload`,
                data: { username: userName, corpus: corpusItem, fileObj},
            });
            if (response && response?.status === 200) {
                getAllFiles();
            } 
        } catch(error) {
            console.log(error);
        }
        
    };

    useEffect(() => {
        getAllFiles();
    },[]);

    const getFileName = (e) => {
        const files = e.target.files;
        const file = files[0];
        setFileObj(file);
        setFilename(file?.name);
    }

    const getAllFiles = async () => {
        setLoader(true);
        try {
            const response = await axiosConfig({
                method: 'post', 
                headers: {
                    'x-functions-key': process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_GET_CORPUS_LOCAL_API_KEY :
                    process.env.REACT_APP_GET_CORPUS_API_KEY,
                    'Content-Type': 'multipart/form-data'
                },
                url: `GetAllCorpus`
            });
            if (response && response?.data && response?.status === 200) {
                    const data = response.data;
                    const allFolders = data.filter((val) => val.CName !== "All");
                    setUserFiles(allFolders);
                    setLoader(false);
                    setError(false);
            } 
        } catch(error) {
            setLoader(false);
            console.log(error.response?.data?.error);
            setError(true);
        }
    }


    const createCorpus = async (cname, desc) => {
        try {
            const response = await axiosConfig({
                method: 'post', 
                headers: {
                    'x-functions-key': process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_CREATE_CORPUS_LOCAL_API_KEY :
                     process.env.REACT_APP_CREATE_CORPUS_API_KEY,
                    'Content-Type': 'application/json'
                },
                url: `CreateCorpus`,
                data: {
                     PartitionKey: "CorpusPK",
                     CName: cname,
                     Description: desc,
                     CreatedBy: userName
                },
            });
            if (response && response?.data && response?.status === 200) {
                    setShow(false);
                    getAllFiles();
            } 
        } catch(error) {
            setShow(false);
            console.log(error.response?.data?.error);
        } 
    }

    const getCorpusDocuments =  async (corpusName) => {
        setCorpusItem(corpusName);
        try {
            const response = await axiosConfig({
                method: 'post', 
                headers: {
                    'x-functions-key': process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_GET_DOCS_LOCAL_API_KEY :
                     process.env.REACT_APP_GET_DOCS_API_KEY,
                    'Content-Type': 'multipart/form-data'
                },
                url: `GetCorpusDocuments`,
                data: {
                     corpus: corpusName,
                },
            });
            if (response && response?.data && response?.status === 200) {
                const dataList = response?.data;
                setCorpuses(dataList);
            } 
        } catch(error) {
            console.log(error.response?.data?.error);
        } 
    };

    return(
        <div className="admin-section">
        <AuthenticatedTemplate>
            {loader && <div className="loading-icon"><ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                /></div>}
                {!loader && !error && (<div className="upload-container-wrapper">
                    <div className="upload-container">
                        <div className="dropdown-container">
                            <div className="corpus-container">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                        {corpusItem}
                                    </Dropdown.Toggle>

                                    {userFiles && userFiles.length !== 0 && ( <Dropdown.Menu>
                                        {userFiles.map((file, index) => (<Dropdown.Item key={index} onClick={(e) => getCorpusDocuments(e.target.innerText)}>{file.CName}</Dropdown.Item>))}
                                    </Dropdown.Menu>)}
                                </Dropdown>
                                <div className="btn-container">
                                    <Button type="button" className="create-btn" onClick={() => setShow(true)} >{CREATE_CORPUS}</Button>
                                </div>
                                <ModalComponent show={show} submitData={createCorpus} close={() => setShow(false)} title={'Corpus Information'}
                                controlLabel1={'Corpus Name'} controlLabel2={'Description'} />
                            </div>
                        </div>
                        <div className="parent-container">
                                <div className="file-upload-section">
                                    <div>
                                    <h6>{UPLOAD_TEXT}</h6>
                                    <p>{UPLOAD_SUBTEXT}</p>
                                    <input type="file" onChange={getFileName} accept=".pdf,text/plain,.csv,.doc,.docx,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    {filename && (<p>{filename}</p>)}
                                    </div>   
                                    <Button className="browse-files" type="button">{BROWSE}</Button>
                                    <div className="submit-btn-container">
                                    <Button className="submit-btn" type="button" onClick={addFile} disabled={!filename}>Upload</Button>
                                    </div>
                                </div>   
                        </div>
                    </div>
                {corpuses && corpuses.length !== 0 && ( 
                <div className="table-container-wrapper">
                        <DataTable
                            columns={columns}
                            data={corpuses}
                            pagination
                            responsive
                            fixedHeader={true}
                            fixedHeaderScrollHeight={size.height > 1000 ? size.width <= 850 ? "40vh" : "70vh" : "40vh"}
                        />
                    </div>)}             
                </div>)}
                {!loader && error && (<p className="error-msg">{ERROR_TEXT}</p>)}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>{PLEASE_LOGIN_TO_CONTINUE}</p>
            </UnauthenticatedTemplate>
        </div>
    )
}

export default UploadDocs;