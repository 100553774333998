import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import "../../styles/results.css";
import { DEVELOPMENT, ERROR_TEXT, JRETURN, PRETURN, PREVIEW } from '../../constants/constants';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';


const PreviewComponent = ({imageMid, constructionMid, constructions}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [previewImg, setPreviewImg] = useState();
    const [doorImg, setDoorImg] = useState();
    const [doorCoords, setDoorCoords] = useState();

    const getPreviewImage = useCallback(async (type, mid) => {
        setLoading(true);
        try {
            const response = await axios({
                method: 'post', 
                data: {mid, type},
                headers: {
                    'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_DOOR_CONFIG_LOCAL_API_KEY :
                     process.env.REACT_APP_DOOR_CONFIG_API_KEY,
                    'Content-Type': 'multipart/form-data'
                },
                url: `${process.env.REACT_APP_BOT_MSG_SERVICE_API_URL}/GetMessage`,
            });
            if (response && response?.data && response?.status === 200) {
                    const data = response.data;
                    if (data) {
                        if (typeof data === 'string') {
                            constructionMid === mid ? setDoorImg(data) : setPreviewImg(data);
                        } else {
                            setDoorCoords(data);
                        }
                    }
                    setLoading(false);
                    setError(false);
            } 
        } catch(error) {
            setLoading(false);
            setError(true);
            console.log(error.response?.data?.error);
        }
    },[constructionMid]);

    const setDoorImage = useCallback(() => {
          // Coordinates from JSON
          console.log(doorCoords);
          const doorCoord = doorCoords?.garage?.point[0];
          if (doorCoord && doorImg) {
            const ul = doorCoord.UL.split(',').map(Number);
            const ur = doorCoord.UR.split(',').map(Number);
            const ll = doorCoord.LL.split(',').map(Number);
            const lr = doorCoord.LR.split(',').map(Number);

            // Calculate door dimensions and position
            const doorWidth = ur[0] - ul[0];
            const doorHeight = ll[1] - ul[1];
            const doorX = ul[0];
            const doorY = ul[1];

            // Get the container element
            const container = document.getElementById('house-img');
            const containerWidth = container.offsetWidth;
            const containerHeight = container.offsetHeight;

            // Scale door dimensions and position to fit within 30% screen size
            const scaledDoorWidth = doorWidth * (containerWidth / 1440);
            const scaledDoorHeight = doorHeight * (containerHeight / 900);
            const scaledDoorX = doorX * (containerWidth / 1440);
            const scaledDoorY = doorY * (containerHeight / 900);

            // Get door image element
            const doorImage = document.getElementById('door-img');
            doorImage.style.width = scaledDoorWidth + 'px';
            doorImage.style.height = scaledDoorHeight + 'px';
            doorImage.style.left = scaledDoorX + 'px';
            doorImage.style.top = scaledDoorY + 'px';
          }
    }, [doorCoords, doorImg]);

    useEffect(() => {
        getPreviewImage(PRETURN, imageMid)
    }, [getPreviewImage, imageMid]);

    useEffect(() => {
        if (constructions && constructions.length > 0) {
            getPreviewImage(PRETURN, constructionMid);
        }
    }, [getPreviewImage, constructions, constructionMid]);

    useEffect(() => {
        getPreviewImage(JRETURN, imageMid);
    }, [getPreviewImage, imageMid]);

    useEffect(() => {
        if (previewImg && doorCoords && constructions && constructions.length > 0) {
            setDoorImage();
        }
    }, [setDoorImage, doorCoords, previewImg, constructions]);


    return (
        <div className='preview-wrapper'>
            <div className='preview-header'>
                <h6>{PREVIEW}</h6>
            </div>
            {loading && <div className="loading-result-icon"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>}
            {!loading && !error && previewImg && (<div className='preview-img' id="preview">
                <img src={previewImg} alt="preview" className='preview-image-item' id="house-img"/>
                {doorImg && (<img src={doorImg} alt="garage-door" class="door-image" id="door-img" />)}
            </div>)}
            {!loading && error && (<p className="error-msg">{ERROR_TEXT}</p>)}
        </div>
    )
}


PreviewComponent.propTypes = {
    mid: PropTypes.string
  };

export default PreviewComponent;