import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { React, useEffect, useRef, useState } from 'react';
import Evoke_logo from "../../assets/icons/Evoke_logo.png";
import NotifyIcon from "../../assets/icons/notification.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import "../../styles/topNavBar.css";
import "../../styles/sidebar.css";
import { CENTER, EGA, EVOKE_GENAI, SIGN_IN, SIGN_IN_USING_POP_UP, SIGN_IN_USING_REDIRECT, LOGOUT, SIGN_OUT_USING_POP_UP, SIGN_OUT_USING_REDIRECT, NOTIFICATION } from '../../constants/constants';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, Overlay, Popover } from "react-bootstrap";
import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { useAuth } from "../../services/Config/userContext";
import useWindowSize from "./useWindowSize";
import SideMenu from "./sideMenu";


const TopNavbar = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [showNavbar, setShowNavbar] = useState(false);
  const ref = useRef(null);
  const size = useWindowSize();

  const { activeAccount, login, logout, loginRedirect, logoutRedirect } = useAuth();

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleLoginPopup = () => {
    login();
  };

  const handleLoginRedirect = () => {
      loginRedirect();
  };

  const handleLogoutPopup = () => {
      logout();
      navigate('/')
  };

  const handleLogoutRedirect = () => {
      logoutRedirect();
  };

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  useEffect(() => {
    if (activeAccount) {
      setUserName(activeAccount.name);
      setEmail(activeAccount.username);
    }
  }, [userName, activeAccount, size]);

  return (
    <nav className="top-navbar">
      <div className="top-navbar-container">
      {size.width > 720 && (<div className="logo">
            <img className="logo-img" src={Evoke_logo} alt="evoke-logo"/>
            <span className='slash'>|</span>
            <div className='logo-text'>
              <Link to="/" className='navbar-link'>
                <span className="logo-name">{EGA} {CENTER}</span>
              </Link>
              <span className='evoke-genai'>{EVOKE_GENAI}</span>
            </div>
        </div>)}
        <img className="menu-icon" src={HamburgerIcon} alt="menu-items" onClick={handleShowNavbar} />
        {showNavbar && (
          <div className={`sidebar-nav-elements  ${showNavbar && 'active'}`}>
              <SideMenu></SideMenu>
          </div>)}
        <div className='user-info'>
          <UnauthenticatedTemplate>
            <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  {SIGN_IN}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleLoginPopup}>{SIGN_IN_USING_POP_UP}</Dropdown.Item>
                  <Dropdown.Item onClick={handleLoginRedirect}>{SIGN_IN_USING_REDIRECT}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <img className="notification-icon" src={NotifyIcon} alt={NOTIFICATION}/>
            <div className="user-badge user-badge-avatar"></div>
            <div className="user-txt">
              <span className='username-txt'>{userName}</span>
              <span className='email-txt'>{email}</span>
            </div>
            <div ref={ref}>
              <img className="logout-icon" src={logoutIcon} alt={LOGOUT} onClick={handleClick} />
              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Body>
                    <p className="logout-link" onClick={handleLogoutPopup}>{SIGN_OUT_USING_POP_UP}</p>
                    <p className="logout-link" onClick={handleLogoutRedirect}>{SIGN_OUT_USING_REDIRECT}</p>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </AuthenticatedTemplate>
        </div>
      </div>
    </nav>
  )
}

export default TopNavbar;