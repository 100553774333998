import React, { useEffect, useMemo, useState } from 'react';
import ReactWebChat, { createDirectLine, createStyleSet } from 'botframework-webchat';
import PropTypes from 'prop-types';
import '../../styles/chatbot.css';
import { BOT, CONNECTING_TEXT, USER } from '../../constants/constants';

const ChatComponent = ({ className, onFetchToken, store, token, username }) => {
  const [avatarOptions, setAvatarOptions] = useState({
    botAvatarInitials: BOT,
    userAvatarInitials: ''
  })
  const directLine = useMemo(() => createDirectLine({ token }), [token]);

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent',
        botAvatarBackgroundColor: '#1B154C',
        userAvatarBackgroundColor: '#1B154C',
        bubbleMaxWidth: '100%'
      }),
    []
  );

  useEffect(() => {
    setAvatarOptions({
      botAvatarInitials: BOT,
      userAvatarInitials: USER
    });
  }, [username]);

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  return token ? (
    <ReactWebChat className={`${className || ''} web-chat`} directLine={directLine} store={store} styleSet={styleSet} 
    styleOptions={avatarOptions} userID={username} username={username}/>
  ) : (
    <div className={`${className || ''} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <span className="ms-Icon ms-Icon--Robot" />
        </div>
        <p>{CONNECTING_TEXT}</p>
      </div>
    </div>
  );
};

ChatComponent.propTypes = {
  className: PropTypes.string,
  onFetchToken: PropTypes.func,
  store: PropTypes.object,
  token: PropTypes.string,
  username: PropTypes.string 
};

export default ChatComponent;
