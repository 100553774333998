import React, { useCallback, useMemo, useState } from 'react';
import { createStore, createStyleSet } from 'botframework-webchat';
import classNames from 'classnames';
import ChatComponent from '../ChatBots/chatComponent';
import '../../styles/chat/fabric-icons-inline.css';
import "../../styles/chatbot.css";
import { FaArrowRightArrowLeft, FaWindowMaximize, FaWindowMinimize} from 'react-icons/fa6';
import { FaWindowClose } from 'react-icons/fa';
import "../../styles/config.css";
import PropTypes from 'prop-types';
import { DEVELOPMENT, PLEASE_SELECT_AN_OPTION, PMO, PROMPT_STUDIO, REFRESH } from '../../constants/constants';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Config from '../Common/config';
import ImageComponent from './imagesComponent';
import ResultComponent from './results';
import PreviewComponent from './preview';
import DesignComponent from './designs';
import ConstructionComponent from './constructions';

const DocSearchChatBot = ({corpus, username, filename, headerName, close, chatWrapperClass}) => {
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [side, setSide] = useState('right');
  const [minimized, setMinimized] = useState(false);
  const [token, setToken] = useState();
  const [images, setImages] = useState([]);
  const [products, setProducts] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [imageMid, setImageMid] = useState();
  const [constructionMid, setConstructionMid] = useState();

  const getProductLists = useCallback(async (midVal) => {
    setLoading(true);
    try {
        const response = await axios({
            method: 'post', 
            data: {mid: midVal, type: 'UIReturn'},
            headers: {
                'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_DOOR_CONFIG_LOCAL_API_KEY :
                 process.env.REACT_APP_DOOR_CONFIG_API_KEY,
                'Content-Type': 'multipart/form-data'
            },
            url: `${process.env.REACT_APP_BOT_MSG_SERVICE_API_URL}/GetMessage`,
        });
        if (response && response?.data && response?.status === 200) {
                const data = response.data;
                if (data[0].hasOwnProperty('SIMAGE_NAME')) {
                  setImages(data);
                  setImageMid(midVal);
                  setProducts([]);
                  setDesigns([]);
                  setConstructions([]);
                }
                if (data[0].hasOwnProperty('SERIES_NAME')) {
                  setProducts(data);
                  setDesigns([]);
                  setConstructions([]);
                } else if (data[0].hasOwnProperty('DESIGN_NAME')) {
                  setDesigns(data);
                  setConstructions([]);
                } else if (data[0].hasOwnProperty('CONSTRUCTION_NAME')) {
                  setConstructions(data);
                  setConstructionMid(midVal);
                }
                setLoading(false);
                setError(false);
                setNewMessage(true);
        } 
    } catch(error) {
        setLoading(false);
        setError(true);
        console.log(error.response?.data?.error);
    }
},[]);

const refreshData = useCallback(async () => {
  try {
      const response = await axios({
          method: 'post', 
          data: {username},
          headers: {
              'x-functions-key':  process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_REFRESH_CHAT_LOCAL_API_KEY :
               process.env.REACT_APP_REFRESH_CHAT_API_KEY,
              'Content-Type': 'multipart/form-data'
          },
          url: `${process.env.REACT_APP_BOT_MSG_SERVICE_API_URL}/CleanUpUserHistory`,
      });
      if (response && response?.status === 200) {
                setImages([]);
                setProducts([]);
                setDesigns([]);
                setConstructionMid([]);   
      } 
  } catch(error) {
      console.log(error.response?.data?.error);
  }
},[username]);


  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }) => next => action => {
        let midVal;
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'webchat/join',
                value: {
                  filename: filename,
                  corpus: corpus,
                  username: username
                },
              }
            });
        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
            if (action.payload.activity.from.role === 'bot') {
              setNewMessage(true);
              if (action.payload.activity.text?.includes('mid')) {
                const midText = action.payload.activity.text;
                midVal = midText.slice(4, midText.length);
                getProductLists(midVal);
              } 
            }
        }
          return midVal ? dispatch({type: 'DIRECT_LINE/INCOMING_ACTIVITY', payload: {activity: {...action.payload.activity, text: PLEASE_SELECT_AN_OPTION}}}) : next(action);
      }),
    [corpus, username, filename, getProductLists]
  );

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent',
        botAvatarBackgroundColor: '#1B154C',
        userAvatarBackgroundColor: '#1B154C',
        bubbleMaxWidth: '100%'
      }),
    []
  );

  const handleFetchToken = useCallback(async () => {
    if (corpus === 'doorconfig') {
      setToken('rdigen4l870.XZ8cxUrRcac_5PuAfxTLASUlrl65JbKu0Wo5_l7VARk');
    } else {
      setToken('OcxNNT9V0dM.WxqoDSMBXKAKzTlIPeXI9zgNS6y9ybVu_8Vxv1Ibj3Y');
    }
  }, [corpus]);

  const handleSwitchButtonClick = useCallback(() => {
    setSide(side === 'left' ? 'right' : 'left');
  }, [setSide, side]);

  const handleMinimizeButtonClick = useCallback(() => {
    setLoaded(false);
    setMinimized(true);
    setNewMessage(false);
  }, [setMinimized,setNewMessage, setLoaded]);

  
  const handleMaximizeButtonClick = useCallback(async () => {
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
  }, [setMinimized, setNewMessage, setLoaded]);

  return (
    <div className={chatWrapperClass}>
      {minimized && chatWrapperClass === 'web-chat-window' && (
        <button className="maximize" onClick={handleMaximizeButtonClick}>
          <FaWindowMaximize size="20px" />
          {newMessage && <span className="ms-Icon ms-Icon--CircleShapeSolid red-dot" />}
        </button>
      )}
      {loaded && (
        <div className={classNames(side === 'left' ? 'chat-box left' : 'chat-box right')}>
          {headerName && (<header>
              <span>{headerName}</span>
              {chatWrapperClass !== 'web-chat-window' && (<div className='web-chat-items'>
                {corpus === 'doorconfig' && (
                    <Button type="button" className="refresh-btn" size='sm' onClick={refreshData} >{REFRESH}</Button>
                )}
                  <Config botName={''} menuItem={PROMPT_STUDIO} path={`/ed.ai/prompt`} state={{botName: headerName, bot: PMO}} />
              </div>)}
              {chatWrapperClass === 'web-chat-window' && ( <div className='btn-wrapper'>
                <button className="switch" onClick={handleSwitchButtonClick}>
                  <FaArrowRightArrowLeft size="20px" />
                </button>
                <button className="minimize" onClick={handleMinimizeButtonClick}>
                  <FaWindowMinimize size="20px" />
                </button>
                <button className="close" onClick={close}>
                  <FaWindowClose size="20px" />
                </button>
              </div>)}
          </header>)}
          <div className={corpus === 'doorconfig' ?  'chat-container-section' : chatWrapperClass === 'web-chat-window' ? 'smart-doc-chat-section' : 'other-chat-section'}>
            <ChatComponent
              username={username}
              className={corpus === 'doorconfig' ?  "doorconfig-web-chat" : "react-web-chat"}
              onFetchToken={handleFetchToken}
              store={store}
              styleSet={styleSet}
              token={token}
            />
            {corpus === 'doorconfig' && (
              <div className='bot-message-service'>
                      {images && images.length > 0 && products.length === 0 && designs.length === 0 && constructions.length === 0 && (
                          <ImageComponent images={images} loading={loading} error={error}/>)}
                      {products && products.length > 0 && designs.length === 0 && constructions.length === 0 && (
                          <ResultComponent products={products} loading={loading} error={error}/>)}
                      {designs && designs.length > 0 && constructions.length === 0 && (
                          <DesignComponent designs={designs} loading={loading} error={error}/>)}
                      {constructions && constructions.length > 0 && (
                          <ConstructionComponent constructions={constructions} loading={loading} error={error}/>)}
                      {images && images.length > 0 && products && products.length > 0 && (<PreviewComponent imageMid={imageMid} constructionMid={constructionMid} constructions={constructions}/>)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DocSearchChatBot.propTypes = {
  corpus: PropTypes.string,
  username: PropTypes.string,
  filename: PropTypes.string,
  headerName: PropTypes.string,
  close: PropTypes.func,
  chatWrapperClass: PropTypes.string,
};

export default DocSearchChatBot;