import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { CLOSE, SUBMIT } from "../../constants/constants";

const ModalComponent = ({show, submitData, close, title, controlLabel1, controlLabel2 }) => {
     const [controlValue1, setControlValue1] = useState('');
     const [controlValue2, setControlValue2] = useState('');

    return(
        <Modal show={show} backdrop="static">
        <Modal.Dialog>
          <Modal.Header closeButton onClick={close}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
               <Form.Label>{controlLabel1}</Form.Label>
               <Form.Control type="text" value={controlValue1} onChange={(e) => setControlValue1(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{controlLabel2}</Form.Label>
              <Form.Control type="text" value={controlValue2} onChange={(e) => setControlValue2(e.target.value)}/>
            </Form.Group>
            </Form>
          </Modal.Body>
  
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>{CLOSE}</Button>
            <Button variant="primary" onClick={() => submitData(controlValue1, controlValue2)}>{SUBMIT}</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    )
}

ModalComponent.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  submitData: PropTypes.func,
  title: PropTypes.string,
  controlLabel1: PropTypes.string,
  controlLabel2: PropTypes.string 
};

export default ModalComponent;