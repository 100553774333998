
import React from "react";
import "../../styles/footer.css";
import { EVOKE_WEBSITE, FOOTER_TEXT } from "../../constants/constants";

const Footer = () => {

    return (
        <footer>
            <div className="footer-container">
                <p className="footer-text" dangerouslySetInnerHTML={{__html:`${FOOTER_TEXT}`}}/>
                <p className="website-text" dangerouslySetInnerHTML={{__html:`${EVOKE_WEBSITE}`}}/>
            </div>
        </footer>
    )
}

export default Footer;