import React from 'react';
import PropTypes from 'prop-types';
import "../../styles/results.css";
import Star_fill from '../../assets/icons/Star_fill.svg'
import { ColorRing } from 'react-loader-spinner';
import { DURABILITY, ENERGY_EFFICIENCY, ERROR_TEXT, LOW_MAINTENANCE, QUIET_OPERATION, SECURITY, SERIES_AND_COLLECTIONS } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const ResultComponent = ({products, loading, error}) => {

    const getRatings = (rating) => {
        const stars = [];
        for (let i = 1 ; i <= rating; i++) {
            stars.push(
                <img className="logo-img" src={Star_fill} alt="evoke-logo" height="10px" width="10px"></img>
            );
        }
        return stars;
    }

    return (
        <div className='results-wrapper'>
            <div className='results-header'>
                <h6>{SERIES_AND_COLLECTIONS}</h6>
            </div>
            {loading && <div className="loading-result-icon"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>}
            {!loading && !error && ( <div className='results-container'>
                <ul className='product-lists'>
                    {products.map((val, index) => (
                        <li key={val.PRODUCT_ID +'_'+index} className='product-item'>
                            <div className='series-heading'>
                                <div className='series-name' dangerouslySetInnerHTML={{__html:`${val.SERIES_NAME}`}}></div>
                                <FontAwesomeIcon className='copy-icon' icon={faCopy} onClick={() => navigator.clipboard.writeText(val.SERIES_NAME)}/>
                            </div>
                            <div className='product-ratings'>
                                <div className='image-section'>
                                    <img src={val.IMAGE_URL} alt={'image'+index} className='config-image'/>
                                </div>
                                <div className='product-ratings-1'>
                                    <div className='rating'>
                                        {ENERGY_EFFICIENCY}
                                        <div className='rating-star'>
                                            {getRatings(val.ENERGY_EFFICIENCY)}
                                        </div>
                                    </div>
                                    <div className='rating'>
                                        {QUIET_OPERATION}
                                        <div className='rating-star'>
                                            {getRatings(val.QUITE_OPERATION)}
                                        </div>
                                    </div>
                                    <div className='rating'>
                                        {SECURITY}
                                        <div className='rating-star'>
                                            {getRatings(val.SECURITY)}
                                        </div>
                                    </div>
                                </div>
                                <div className='product-ratings-2'>
                                    <div className='rating'>
                                        {DURABILITY}
                                        <div className='rating-star'>
                                            {getRatings(val.DURABILITY)}
                                        </div>
                                    </div>
                                    <div className='rating'>
                                        {LOW_MAINTENANCE}
                                        <div className='rating-star'>
                                            {getRatings(val.LOW_MAINTENANCE)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li> 
                    ))}
                </ul>
            </div>)}
            {!loading && error && (<p className="error-msg">{ERROR_TEXT}</p>)}
        </div>
    )
}


ResultComponent.propTypes = {
    products: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.bool
  };

export default ResultComponent;