import React from 'react';
import PropTypes from 'prop-types';
import "../../styles/results.css";
import { ColorRing } from 'react-loader-spinner';
import { ERROR_TEXT, STOCK_IMAGES } from '../../constants/constants';


const ImageComponent = ({images, loading, error}) => {

    return (
        <div className='results-wrapper'>
            <div className='results-header'>
                <h6>{STOCK_IMAGES}</h6>
            </div>
            {loading && images.length === 0 && (<div className="loading-result-icon"><ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>)}
            {!loading && !error && ( <div className='results-container'>
                <ul className='design-lists'>
                    {images.map((val, index) => (
                        <li key={val.IMAGEID+'_'+index} className='design-item'>
                            <div className="design-image">
                                <img src={val.THUMBIMAGE} alt={val.ALTTEXT} className='design-image-item'/>
                                <div className='design-name'>
                                    {val.SIMAGE_NAME}
                                </div>
                            </div>
                        </li> 
                    ))}
                </ul>
            </div>)}
            {!loading && error && (<p className="error-msg">{ERROR_TEXT}</p>)}
        </div>
    )
}


ImageComponent.propTypes = {
    images: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.bool
  };

export default ImageComponent;