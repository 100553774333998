
import {React, useEffect, useState } from 'react';
import '../../styles/sidebar.css';
import MortgageActiveIcon from "../../assets/icons/mortgage.svg";
import PMOActiveIcon from "../../assets/icons/pmo.svg";
import PMOIcon from "../../assets/icons/pmo_unselected.svg";
import SQLActiveIcon from "../../assets/icons/sql.svg";
import SQLIcon from "../../assets/icons/sql_unselected.svg";
import MortgageIcon from "../../assets/icons/mortgage_unselected.svg";
import TestActiveIcon from "../../assets/icons/test-cases-w.svg";
import TestIcon from "../../assets/icons/test-cases-g.svg";
import CodeGeneratorIcon from "../../assets/icons/code-generator-g.svg";
import CodeGeneratorActive from "../../assets/icons/code-generator-w.svg";
import DocSearchActive from "../../assets/icons/doc-search.svg";
import DocSearch from "../../assets/icons/doc-unselected.svg";
import HRActiveIcon from "../../assets/icons/hr.svg";
import HRIcon from "../../assets/icons/hr_unselected.svg";
import { AGENTS, ASSISTANT, CODE, CODE_GENERATOR, DOCS, HRA, HRA1, HR_ASSISTANT, HR_ASSISTANT_1, MORTGAGE_LOAN_ASSISTANT, NRL, PMO, PMO_ASSISTANT, SMART_DOCUMENT_SEARCH, SQLA, SQL_AGENT, TEST, TEST_ASSISTANT } from '../../constants/constants';
import { Accordion } from 'react-bootstrap';
import SidebarLink from '../Links/sideMenuLink';
import { useLocation } from 'react-router-dom';

const SideMenu = () => {
  const [activeItem, setActiveItem] = useState();
  const [url, setUrl] = useState('');
  const [sidebarView, setSidebarView] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname?.includes('doorconfig')) {
        setSidebarView(false);
    } else {
        setSidebarView(true);
    }
    const baseURL = process.env.REACT_APP_BOT_BASE_URL;
    const botInitial = location?.state?.bot;
    setUrl(baseURL);
    setActiveItem(botInitial || DOCS);
  }, [location]);


  return (
    <>
    {sidebarView && (<nav className='sidebar'>
       <div className='menu-content'>
            <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                <Accordion.Item eventKey={"0"}>
                    <Accordion.Header bsPrefix='acc-header'>{ASSISTANT}</Accordion.Header>
                    <Accordion.Body bsPrefix='acc-body'>
                        <ul className='menu-items'>
                            <SidebarLink onLinkClick={() => setActiveItem(DOCS)} activeItem={activeItem} pathname={"/ed.ai"} url={undefined} secondaryPath={undefined}
                            botName={SMART_DOCUMENT_SEARCH} botInitials={DOCS} defaultIcon={DocSearch} activeIcon={DocSearchActive} />
                            
                            <SidebarLink onLinkClick={() => setActiveItem(HRA)} activeItem={activeItem} pathname={"/ed.ai/bots"} url={url} secondaryPath={'hrbot'}
                            botName={HR_ASSISTANT} botInitials={HRA} defaultIcon={HRIcon} activeIcon={HRActiveIcon} />
                            
                            <SidebarLink onLinkClick={() => setActiveItem(HRA1)} activeItem={activeItem} pathname={"/ed.ai/bots"} url={url} secondaryPath={'hrplus'}
                            botName={HR_ASSISTANT_1} botInitials={HRA1} defaultIcon={HRIcon} activeIcon={HRActiveIcon} />

                            <SidebarLink onLinkClick={() => setActiveItem(NRL)} activeItem={activeItem} pathname={"/ed.ai/bots"} url={url} secondaryPath={'nrlbot'}
                            botName={MORTGAGE_LOAN_ASSISTANT} botInitials={NRL} defaultIcon={MortgageIcon} activeIcon={MortgageActiveIcon} />

                            <SidebarLink onLinkClick={() => setActiveItem(PMO)} activeItem={activeItem} pathname={"/ed.ai/pmo"} url={undefined} secondaryPath={undefined}
                            botName={PMO_ASSISTANT} botInitials={PMO} defaultIcon={PMOIcon} activeIcon={PMOActiveIcon} />
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={"1"}>
                    <Accordion.Header className='acc-header'>{AGENTS}</Accordion.Header>
                    <Accordion.Body bsPrefix='acc-body'>
                        <ul className='menu-items'>
                            <SidebarLink onLinkClick={() => setActiveItem(SQLA)} activeItem={activeItem} pathname={"/ed.ai/bots"} url={url} secondaryPath={'agent'}
                            botName={SQL_AGENT} botInitials={SQLA} defaultIcon={SQLIcon} activeIcon={SQLActiveIcon} />

                            <SidebarLink onLinkClick={() => setActiveItem(TEST)} activeItem={activeItem} pathname={"/ed.ai/test"} url={undefined} secondaryPath={undefined}
                            botName={TEST_ASSISTANT} botInitials={TEST} defaultIcon={TestIcon} activeIcon={TestActiveIcon} />

                            <SidebarLink onLinkClick={() => setActiveItem(CODE)} activeItem={activeItem} pathname={"/ed.ai/bots"} url={`http://172.174.228.126:8000`} secondaryPath={undefined}
                            botName={CODE_GENERATOR} botInitials={CODE} defaultIcon={CodeGeneratorIcon} activeIcon={CodeGeneratorActive} />
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div> 
    </nav>)}
    </>
  )
}

export default SideMenu;