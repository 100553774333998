export const DEVELOPMENT = "development";
export const FOOTER_TEXT = "&#169; Evoke Technologies Pvt. Ltd. &#169; 2024 All rights reserved.";
export const EVOKE_WEBSITE = "www.evoketechnologies.com";
export const CLOSE = "Close";
export const SUBMIT = "Submit";
export const ERROR_TEXT = "Some error has occured";
export const BROWSE = "Browse";
export const CREATE_CORPUS = "Create Corpus";
export const UPLOAD_TEXT = "Upload or drag and drop";
export const UPLOAD_SUBTEXT = "upload pdf, text, excel or word files";
export const CONNECTING_TEXT = "Please wait while we are connecting.";
export const BOT = "Bot";
export const USER = "User";
export const ADMIN = "Admin";
export const ADMINISTRATION = "admin";
export const PROMPT_ID = "Prompt ID";
export const CHAT = "chat";
export const PROMPT = "prompt";
export const ALL = "All";
export const CORPUS_NAME = "Corpus Name";
export const DESCRIPTION = "Description";
export const CHAT_TEXT = "Chat";
export const ADMINISTER = "Administer";
export const PROMPT_CRAFT = "Prompt Craft";
export const SMART_DOC_SEARCH_BOT = "Smart Doc Search Bot";
export const PMO = "PMO";
export const PMO_ASSISTANT = "PMO Assistant";
export const ADMINISTRATION_TEXT = "Administration";
export const PROMPT_STUDIO = "Prompt Studio";
export const HISTORY = "History";
export const EGA = "EGA";
export const SQL_AGENT = "SQL Agent";
export const CENTER = "Center";
export const MY_INSTRUCTION = "My Instruction";
export const BASE_URI = "https://ega-api.evokeapps.com";
export const SMART_DOC_SEARCH = "Smart Doc Search";
export const HR_ASSISTANT = "HR Assistant";
export const MORTGAGE_LOAN_ASSISTANT = "Mortgage Loan Assistant";
export const NRL = "NRL";
export const SQLA = "SQLA";
export const HRA = "HRA";
export const SQL = "SQL";
export const HR = "HR";
export const MORTGAGE = "mortgage";
export const TEST = "Test";
export const TEST_ASSISTANT = "Test Case Generator";
export const SMART_DOCUMENT_SEARCH = "Smart Document search";
export const DOCS = "docs";
export const CODE_GENERATOR = "Code Generator";
export const CODE = "Code";
export const SAVE = "Save";
export const DELETE = "Delete";
export const SEARCH_PROMPT_CRAFT = "Search Prompt Craft";
export const SEARCH_CORPUS = "Search Corpus";
export const AGENTS = "Agents";
export const ASSISTANT = "Assistant";
export const HR_ASSISTANT_1 = "HR Assistant +";
export const HR1 = "HR1";
export const HRA1 = "HRA +";
export const EXPLORE_EVOKE_TOOLS = "Explore Evoke Generative AI tools";
export const SEARCH_ASSISTANT = "Search Assistants, Tools";
export const ENABLE_DISABLE = "Enable/Disable";
export const EVOKE_GENAI = "Evoke GenAI";
export const TEST_GENERATOR = "Test Case Generator";
export const SELECT_INPUT_TYPE = "Select Input Type";
export const REQUIREMENTS_AS_INPUT = "Requirements as input";
export const CODE_SNIPPET_AS_SAMPLE = "Code snippet as sample";
export const PLACEHOLDER_TEXT = "Prompt text placeholder";
export const GENRATE_MANUAL_TEST = "Generate manual test cases";
export const GENERATE_SELENIUM_SCRIPT = "Generate Selenium Script";
export const TEST_GROUP = "test-group";
export const SIGN_IN = "Sign in";
export const LOGOUT = "Logout";
export const SIGN_IN_USING_POP_UP = "Sign in using Popup";
export const SIGN_IN_USING_REDIRECT = "Sign in using Redirect";
export const SIGN_OUT_USING_POP_UP = "Sign out using Popup";
export const SIGN_OUT_USING_REDIRECT = "Sign out using Redirect";
export const PLEASE_LOGIN_TO_CONTINUE = "Please login to continue";
export const NOTIFICATION = "Notification";
export const SERIES_AND_COLLECTIONS = "Series and Collections";
export const ENERGY_EFFICIENCY = "Energy Efficiency";
export const QUIET_OPERATION = "Quiet Operation";
export const SECURITY = "Security";
export const DURABILITY = "Durability";
export const LOW_MAINTENANCE = "Low Maintenance";
export const PREVIEW = "Preview";
export const PRETURN = "PRETURN";
export const DOOR_DESIGN = "Door Design";
export const CONSTRUCTION_OF_THE_DOOR = "Construction of the door";
export const STOCK_IMAGES = "Stock Images";
export const DOOR_CONFIG = "Door Config";
export const JRETURN = 'JRETURN';
export const CPQ = "CPQ";
export const PLEASE_SELECT_AN_OPTION = "Please select an option";
export const TEXT_IS_LOADING = "Please wait. Prompt is loading..";
export const ERROR_OCCURED = "Some error encountered..";
export const REFRESH = "Refresh";
export const SCREEN_CAST = "Record a Screencast";
export const SETTINGS = "Settings";
export const INFO = "Information";
