import React from 'react';
import DocChatLoader from '../SmartDocuChatBot/docChatLoader';

const DoorConfigComponent = () => {

  return (
      <DocChatLoader wrapperClass={'chat-door-config'} chatHeader={'Door Config Bot'} corpus={'doorconfig'} chatWrapperClass={'web-chat-config-window'}/>
  )
};

export default DoorConfigComponent;