import { Outlet } from 'react-router-dom';
import TopNavbar from './topNavBar';
import './../../App.css';

const NavbarLayout = () => (
  <>
    <TopNavbar />
    <Outlet />
  </>
);

export default NavbarLayout;